import React, { useState, useEffect } from 'react';
import './css/home.css'

const Home = () => {

    return (
        <div className="home">
            <h3>HelloFriendAI</h3>
        </div>
    )
}

export default Home;