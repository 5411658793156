import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './js/screens/Home';


function App() {
  return (
    <Router>
      <Routes>
        {/* <Route exact path="/" element={<HomePage />} /> */}
        {/* <Route exact path="/plans" element={<Plans />} /> */}
        <Route exact path="/" element={<Home />} />
        {/* <Route exact path="/options" element={<SinglePaymentOptions />} /> */}
        {/* <Route exact path="/status" element={<Status />} /> */}
        {/* <Route path="/authenticated" element={<Authenticated />} /> */}
        {/* <Route path="/testpage" element={<TestPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
